import React from "react"

export default function PlayerButtonPlay({ playing = 0, onClick = () => {} }){

  return playing === 1 ? (
    <div className='inline wh-54 round-full center tapable border-primary'
      data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>Space</span><span class='t-6 ml-10 th-32'>Play/Pause</span>"
      onClick={ onClick }>
      <div className="wave-boxes">
        <div className="box box1"></div>
        <div className="box box2"></div>
        <div className="box box3"></div>
        <div className="box box4"></div>
        <div className="box box5"></div>
      </div>
    </div>
  ) :
  (
    <div className='inline wh-54 round-full center tapable border-primary bg-primary darken'
      data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>Space</span><span class='t-6 ml-10 th-32'>Play/Pause</span>"
      onClick={ onClick }>
      <span className={`icon icon-${ ['play','play','','refresh'][playing+1] } black th-54`} />
    </div>
  )
}