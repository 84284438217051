import React, { useCallback } from "react"
import PlayerButtonPlay from "./player-button-play"
import useKeyPress from "../../../hooks/useKeyPress"
import ProgressBar from "../../../components/progress-bar"
import _player from "../_player"
import { observer } from "mobx-react-lite"

function TrackPanel({ player }){

  const { playing, at, duration, cc, speed, lineIdx } = _player

  const seekLine = useCallback((delta = 1)=>{
    player.current.seekTo( _player.getLine(delta).start, 'seconds')
  },[ player ])

  const onToggle = () => playing > -2 && _player.set('playing', playing === 1 ? 0 : 1 )
  const onCC = () => _player.set('cc', cc === 0 ? 1 : 0 )
  const onSpeed = () => _player.set('speed', speed === 1 ? .75 : 1 )
  
  // Hot Keys
  useKeyPress('keydown',{
    'Space' : onToggle,
    'ArrowUp' : () => seekLine(-1),
    'ArrowDown' : () => seekLine(1),
    'KeyC' : onCC,
    'KeyS' : onSpeed,
  })

  return (
    <div className='container bg-black-blur shadow-black border-ink py-20 px-10 round'>
      <div className="ab-tl w-full">
        <ProgressBar width='100%' height='3px'
          bars={[
            [100,'black'],
            [ at/duration * 100, playing === 1 ? ( lineIdx%2 ? 'primary' : 'yellow' ) : 'silver'],
            [0,'yellow']
          ]}/>
      </div>
      <div className='flex-row h-54'>
        <div className="flex-none left">
          <div className='inline w-48 center tapable tap-expand'
            data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>C</span><span class='t-6 ml-10 th-32'>Close Caption</span>"
            onClick={ onCC }>
            <span className={`t-2 ${ !cc ? 'primary' : 'silver' } th-54 icon icon-cc-off`} />
          </div>
        </div>
        <div className="flex-1 flex-m-1 center flex-center">
          <div className='inline wh-48 border-silver round-full center tapable tap-expand mr-10'
            data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>↑</span><span class='t-6 ml-10 th-32'>Previous Line</span>"
            onClick={ () => seekLine(-1) }><span className='t-4 silver th-48 icon icon-up' /></div>
          <PlayerButtonPlay playing={ playing } onClick={ onToggle } />
          <div className='inline wh-48 border-silver round-full center tapable tap-expand ml-10'
            data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>↓</span><span class='t-6 ml-10 th-32'>Next Line</span>"
            onClick={ () => seekLine(1) }><span className='t-4 silver th-48 icon icon-down' /></div>
        </div>
        <div className="flex-none right">
          <div className={`inline w-48 center tapable tap-expand ${ speed !== 1 ? 'primary' : 'silver' }`}
            data-tooltip-id="toptip" data-tooltip-html="<span class='hotkey'>S</span><span class='t-6 ml-10 th-32'>Speed</span>"
            onClick={ onSpeed }>
            <span className='t-2  th-54 icon icon-speed' />
            <span className='t-7 bold ab-bl center w-48' style={{ lineHeight:'1em', bottom:'10px', letterSpacing:0 }}>
              <span className='bg-black px-2'>x{ speed }</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(TrackPanel)