import React, { useCallback, useEffect } from 'react';
import Markdown from './markdown';
import { useState } from 'react';
import Input from './input';
import $api from '../core/api';
import toast from '../core/toast';
import util from '../core/util';

export const MsgUserNormal = ({ data }) => {
  return <div className="msg msg-user right">
    <div className='inline round p-15 round bg-blue left'>
      <div className="left">
        <Markdown content={ data.content } />
      </div>
    </div>
  </div>
}

export const MsgUserInput = ({ data }) => {
  const { minLen = 1, maxLen = 250 } = data.pending
  const [text,setText] = useState('')

  const send = useCallback(async _t =>{
    const content = _t.slice().replace(/^ */,'').replace(/ *$/,'')
    if( content.length < minLen ) return toast(`Too short! (max:${minLen})`)
    if( content.length > maxLen ) return toast(`Too long! (max:${maxLen})`)
    await $api.post(`/message/${data.id}/resolve/input`,{ content })
    setText('')
    $api.post(`/chat/${data.cid}/next`)
  },[data.id, minLen, maxLen,data.cid])

  useEffect(()=>{
    const _input = util.local.get('chat-user-input', { })
    if( _input.text ){
      util.local.remove('chat-user-input')
      window.setTimeout(()=>send(_input.text),200)
    }
  },[send])

  return (
    <div className="msg msg-user-input right">
      <Input placeholder='Write...' className='bg-ink border-gray round focus-blue'
        value={ text } onChange={ setText } focus
        minRows={3}
        onEnter={ ()=> send(text) } />
    </div>
  )
}