const $v = {

  get ism(){ return navigator.userAgent.toLowerCase ().match (/mobile/i) }, // window.innerWidth < 1120
  get iswx(){ return navigator.userAgent.indexOf("MicroMessenger") > -1 },

  twitURL : 'https://twitter.com/intent/tweet?' + [
      'text='+ encodeURIComponent("Turn YouTube into your personal language learning gym!\n\n And find more AI-base language learning tools in our app. \n Listening, Speaking, Thinking and Writing! \n"),
      'url=' + encodeURIComponent('🫱 https://holli.cc\n\n'),
      'hashtags=' + encodeURIComponent('LanguageLearning,HolliTube,SeamlessLearning,ChromeExtension,LearningTool,IndieHacker'),
      'via=' + encodeURIComponent('halfong'),
      'related=' + encodeURIComponent('halfong'),
  ].join('&'),
  
}

export default $v