import $api from "../core/api"
import React, { useCallback } from "react"
import { openWordBox } from "./box-word"
import useReachBottom from "../hooks/useReachBottom"
import useList from "../hooks/useList"

export default function ListWords({ psize=20, autoLoad = true }){

  const { items: words = [], next, setItems: setWords, overed } = useList('/word/list', psize )
  const loadListRef = useReachBottom( () => autoLoad && !overed && next(), 100 )

  const unstarWord = useCallback( _w =>{
    const _idx = words.findIndex( _w2 => _w2.text === _w.text )
    _idx > -1 && setWords( words.slice(0,_idx).concat( words.slice(_idx+1) ) )
    $api.post('/user/words/toggle', { lang: _w.lang, text: _w.text, to: false })
  },[words,setWords])

  return words.length === 0 ?
    <div className="py-30 center">
      <div><span className="icon icon-translate gray" style={{ fontSize:'60px' }}/></div>
      <p className='gray t-5 bold'>No words collected</p>
      <p className='white t-6 left mt-50 px-20 py-10 round bg-black'>
        <span className="blue bold mb-5 inline">Auto-Collect</span> words (when you click it to translate) from:
        <br/>- <span className="bold">YouTube Subtitles</span> (with HolliTube extension)
        <br/>- <span className="bold">Holli Chats</span>
      </p>
    </div>
    :
    <div ref={ loadListRef } className='word-list left'>
      { words.map( _w =>
        <div key={ _w.lang+'-'+ _w.text }
          className='tapable border-lighten-b py-15 flex-row flex-center hover'
          onClick={ e => openWordBox( _w.text, e.target ) }>
          <p className='flex-none'><span className="t-7 px-5 round bold gray inline mt-5">{ _w.lang?.toUpperCase() }</span></p>
          <p className='th-4 flex-1 pl-10'>{ _w.text }</p>
          <p className="flex-2 t-6 gray right">
            { ( _w.translation?.defs?.TRAN || [] ).join(', ') }
          </p>
          <div className="flex-none wh-32 center tapable" onClick={ e => { e.stopPropagation(); unstarWord(_w) } }>
            <span className="th-4 icon icon-delete hover-show gray"/>
          </div>
        </div>
      )}
    </div>
}