import React from "react";

export default function PageFooter({ bottom = false }){

  return (
    <div className={`center py-20 w-full ${bottom ? 'ab-bl' : ''}`}>
      <p className="t-6 gray">Made by <a className="t-6 underline" href="https://twitter.com/halfong" target="_blank" rel="noreferrer" >@halfong</a> with ❤️</p>
      <p className="t-6 gray">
        <a className="t-6 gray tapable" href="https://holli.cc/md/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> / <a className="t-6 gray tapable" href="https://holli.cc/md/terms" target="_blank" rel="noreferrer">Terms of use</a>
      </p>
    </div>
  )

}