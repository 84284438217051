import React from "react"
import util from "../core/util"
import FormOTP from "./form-otp"

function BoxLoginWechat({ visible = false, onSubmit }){

  return (
    <div className={`bg-black shadow border-ink round corp p-20 center ani-popin ${ visible ? 'visible':''}`}
      style={{ maxWidth:'360px', margin:'10vh auto 0 auto' }}>
      <img src={ util.statics.wemp_qrcode } alt='wechat qrcode' className='round mt-20 wh-160'/>
      <p className='t-6 silver'>扫码关注，更多 - 生成令牌</p>
      <FormOTP classes='mt-30'
        submit={ ( handle, otp ) => onSubmit && onSubmit({ handle, otp }) } />
    </div>
  )
}


export default BoxLoginWechat