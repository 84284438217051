import { useEffect, useState } from "react";

/**
 * const value = useEffectDelay(()=>{}, [ ...dependencies ])
 * - e.g. const visible = useStateDelay( true, [isLoading], 100 )
 * @param {Function} getter () => value
 * @param {array} deps []
 * @param {Number} delay
 */
export default function useStateDelay( defaultValue = undefined, getter,  delay = 0, deps = [] ) {
  const [ val, setVal ] = useState(defaultValue)
  useEffect( ()=> {
    delay ? setTimeout( () => setVal(getter()), delay ) : getter()
  // eslint-disable-next-line
  }, deps) 
  return val
};