import React from 'react'
import Ani from "./ani"

/**
 * absolute Box at Anchor Element
 * @param alignX: auto, left, right, center @next
 * @returns 
 */
export default function Box({ width=280, anchorRect = null, ani='popin', children, centerX = false, className='' }){

  // Auto Align Left/Right, Top/Bottom
  const { ab_x, ab_y, atT, atL, width: w, height:h } = anchorRect || {}
  const style = ab_x ? {
    width:`${width}px`, position:'absolute', zIndex:200,
    left : centerX ? ( ab_x - width/2 + w/2 ) + 'px'
      : ( atL ? ab_x : ( ab_x + w - width ) ) + 'px',
    top : atT ? (ab_y + h + 'px') : undefined,
    bottom : !atT ? ( document.body.scrollHeight - ab_y + 'px' ) : undefined,
  } : { display:'none' }

  return (
    <Ani className={`com-box ${className}`} show={ !!anchorRect } delay={20} type={ ani } style={ style }>
      { children }
    </Ani>
  )
}