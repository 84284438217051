import React, { useEffect } from "react"
import util from "../../../core/util"
import $v from "../../../core/values"
import _player from "../_player"
import { observer } from "mobx-react-lite"
import { openWordBox } from "../../../components/box-word"

function TrackCaption(){

  const { cc, lines, lineIdx, playing } = _player
  const isBlur = cc === 0 && ( playing !== 0 )

  useEffect(()=>{
    cc === 2 && util.scrollToLast(`#caption-line-${lineIdx}`, - window.innerHeight*1/3 )
  },[cc, lineIdx])

  return (
    <div className='com-track-caption left '>
      { ( cc === 1 || cc === 0 ) && lineIdx > -1 &&
        <div id={`caption-line-${lineIdx}`} className="com-track-caption-line py-10 flex-center" key={ lineIdx }
          style={{ minHeight:'50vh', filter: cc === 0 && isBlur ? 'blur(12px)' : 'none' }}>
          <p className='center white'
            style={ $v.ism ? { fontSize:'5vh', lineHeight:'6.4vh' } : { fontSize:'4vw', lineHeight:'4.8vw' }}>
            <WordSpans text={ lines[lineIdx].text } />
          </p>
        </div>
      }
      { cc === 2 && <>
        { lines.map( _l =>
          <div id={`caption-line-${_l.idx}`} className="com-track-caption-line py-10" key={ _l.idx }>
            <p className={`t-4 ${ lineIdx === _l.idx ? 'white' : 'silver'}`}>
              <WordSpans text={ _l.text } />
            </p>
          </div>
        )}
        <div style={{ height:'38vh' }}></div>
      </>}
    </div>
  )
}

const WordSpans = ({ text = '' })=>{
  return text.split(' ').map((_t,_i) => {
    if( _t === '\n' ) return '\n'
    else if( _t.length < 1 ) return null
    else return <span key={ _i } onClick={ e => openWordBox( _t , e.target, text ) } className="word tapable">{_t} </span>
  })
}

export default observer(TrackCaption)