import { useEffect } from "react";

/**
 * @param {string} type keydown, keyup, ...
 * @param {object} bindings { keyCode : callback, ... }
 */
export default function useKeyPress( type='keydown', bindings = {} ) {
  useEffect(() => {
    const callback = e => {
      // console.log('[KeyPress]', e.type, e.code )
      const _call = bindings[e.code]
      if( _call ){ e.preventDefault(); _call(e) }
    }
    window.addEventListener( type , callback)
    return () => window.removeEventListener( type , callback)
  }, [type,bindings]);
};