import React from "react"
import { useNavigate } from "react-router-dom"
import useSWRImmutable from "swr/immutable"
import { getLang } from "../assets/langs"
import userAvatar from "../assets/images/default_user_avatar.jpg"
import useStateDelay from "../hooks/useStateDelay"

const TABS = {
  home : { label:'Home', path:'/home', icon:'icon-home' },
  vocab : { label:'Vocab', path:'/vocab', icon:'icon-vocab' },
  // ts : { label:'Reading', path:'/transcript', icon:'icon-cc-sull' },
  // profile : { label:'Profile', path:'/profile', icon:'icon-account' },
}

/**
 * Height is 84px
 */
// @fix without prev route
export default function Topnav({ title = null, style={}, sub=false }){

  const navigate = useNavigate()
  const {data: me = {} } = useSWRImmutable('/auth/me')
  const { data : countWord = 0 } = useSWRImmutable('/word/count')
  const currPath = useStateDelay('',()=>new URL( window.location.href).pathname,20) 
  TABS.vocab.count = countWord

  return (
    <div id="com-topnav" className="com-topnav h-84 flex-row flex-center px-20" style={{ maxWidth:'1400px', margin:'0 auto', ...style}}>

      {/* Left */}
      <div className="flex-1 h-84">
        { !sub ?
          // Logo
          <div className="inline tapable" onClick={ () => navigate('/home',{replace:true}) }>
            <div className="inline wh-44 bg-primary center mr-10" style={{ marginTop:'20px', borderRadius:'16px' }}>
              <span className="icon icon-ho black th-44" style={{ fontSize:'36px'}} />
            </div>
            <h1 className="inline th-84 bold primary">Holli</h1>
          </div>
          : // Back Button
          <div className="inline tapable" style={{ padding:'18px 0' }} onClick={ () => navigate(-1) }>
            <div className="inline wh-48 bg-ink border-lighten round-full center tapable">
              <span className="primary icon icon-left t-3 th-48" />
            </div>
          </div>
        }

        { !sub ?
          // Main Menu
          <div className="inline left ml-20" style={{ margin:'24px 0'}}>
            { Object.values(TABS).map( _i =>
              <div key={ _i.path }
                className={`com-topnav-item inline h-36 px-5 mx-5 tapable ${ currPath === _i.path ? 'focus' : '' }`}
                onClick={ ()=>navigate( _i.path ) }>
                {/* <span className={`t-4 th-36 icon ${_i.icon }`} /> */}
                <span className='t-5 th-36 ml-2 bold'>{ _i.label }</span>
                { !!_i.count && <span className='ab-tr px-8 t-7 bold box-shadow center round bg-primary black shadow-primary'
                  style={{ right:'-1.6em', top:'0px' }}>{ _i.count }</span> }
              </div>
            )}
          </div>
          :
          <div className="inline left pl-10">
            <h2 className="inline th-84 t-5">{ title || '' }</h2>
          </div>
        }
      </div>


      {/* Right @next */}
      <div className="flex-none h-84 right tapable" onClick={ () => navigate('/profile') }>
        <div className="inline right mt-20 pt-5">
          {/* <p className="t-6 th-7 bold">{ me.nickname || 'No Name' }</p> */}
          <p className="t-6 th-36 bg-ink border-lighten h-36 round px-10">
            <span className="icon icon-goal2 bold t-5 mr-5 primary" style={{ verticalAlign:'middle' }}/>
            <span className="bold primary">{ me.settings?.language_target ? getLang(me.settings?.language_target).nativeName : 'Unknown' }</span>
          </p>
        </div>
        <img alt="user avatar" className="wh-48 border-gray round-full bg-ink ml-5"
          style={{marginTop:'18px'}} src={ me.avatar || userAvatar } />
      </div>
    </div>
  )
}