import React from "react"

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function FoRadio({
  value = ['options1'],
  onChange,
  limit = 1,
  
  options = ['option1','option2','option3'],  //or [{ valueKey, labelKey }, ...]
  valueKey = null,
  labelKey = null,
}){
  return (
    <div className="ff-radio">
      { options.map( _o => (
        <div key={ valueKey ? _o[valueKey] : _o } className={ "ff-option " + ( value.indexOf( valueKey ? _o[valueKey] : _o ) > -1 ? 'selected' : '') }
          onClick={ () => onChange( uniquePush( value.slice(), valueKey ? _o[valueKey] : _o, limit ) ) }>
          <p className='ff-label'>{ labelKey ? _o[labelKey] :  _o }</p>
        </div>
      )) }
    </div>
  )
}

const uniquePush = ( arr, val, limit = 1 )=>{
  const i = arr.indexOf( val )
  i > -1 ? arr.splice( i, 1 ) : arr.push( val )
  arr.length > limit && arr.shift()
  return arr
}