import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const size = 54
const fontSize = 18

/**
 * 基于TextareaAutosize实现
 */
export default function Input({
  className='bg-ink border-lighten',
  value, onChange,
  onEnter=null,
  placeholder = 'Write...',
  icons = [], // ['icon-help gray','icon-play']
  minRows = 1, maxRows = 10,
  focus = false,
}){


  //监听HostKey
  // @next add hotkey to resume last input content
  const __hotkey = e => {
    if( onEnter && e.key === 'Enter' && (e.metaKey || e.altKey ) ){
      e.stopPropagation()
      e.preventDefault()
      onEnter(value)
    }
  }
  const enterTip = {'macOS':'Cmd+Enter','windows':'Alt+Enter','other':'Alt+Enter',}[detectPlatform()]

  return (
    <div style={{ minHeight:size+'px', fontSize:fontSize+'px', lineHeight: fontSize*1.3+'px' }}>
      <TextareaAutosize className={`left block ${className}`} style={{
          width:'100%', height:size+'px', borderRadius:size/2+'px',
          paddingTop:`${(size-fontSize*1.3)/2}px`,paddingBottom:`${(size-fontSize*1.3)/2}px`,
          paddingLeft: ( icons[0] ? 2.4 : .8 ) * fontSize + 'px',
          paddingRight: ( icons[0] ? 2.4 : .8 ) * fontSize + 'px',
        }}
        value={ value } autoFocus = { focus }
        minRows={ minRows }
        maxRows={ maxRows }
        placeholder={ placeholder }
        onChange={ e => onChange && onChange(e.target.value,e) }
        onKeyDown={ __hotkey } />

      { icons[0] && <div className='ab-tl center' style={{ padding:'0 .2em' }}>
          <span className={`th-${size} center icon ${icons[0]}`} />
        </div>
      }
      { onEnter ?
        ( value.length<1 ?
          <div className='ab-br flex-none center' style={{ padding:'0 1em', fontSize:fontSize*.7 + 'px', opacity:'.3' }}>
            <span className={`th-${size} center`}>{ enterTip }</span>
          </div>
          :
          <div className='ab-br flex-none center tapable' style={{ padding:'0 .6em' }} onClick={ onEnter }>
            <span className={`th-${size} center icon icon-right-arrow primary`} />
          </div>
        ) :
        icons[1] &&
        <div className='ab-br flex-none center' style={{ padding:'0 .2em' }}>
          <span className={`th-${size} center icon ${icons[1]}`} />
        </div>
      }
    </div>
  )

}

const detectPlatform = () => {
  const userAgent = window.navigator.userAgent;
  if (/Mac/i.test(userAgent)) {
    return 'macOS';
  } else if (/Win/i.test(userAgent)) {
    return 'windows';
  } else {
    return 'other';
  }
};
