import React from 'react'
import { useParams } from 'react-router-dom'

import useSWRImmutable from 'swr/immutable'
import { observer } from 'mobx-react-lite'
import { AppFrame } from './App'
import util from '../core/util'
import toast from '../core/toast'
import { openWordBox } from '../components/box-word'

function TranscriptDetail(){

  const { tsid } = useParams()
  const { data: ts = { params: {}, r_Track: { srt : '' }, state: -1 } } = useSWRImmutable(`/transcript/${tsid}`)

  const lines = plainLines( ts.words )

  return (
    <AppFrame title={ ts.title } sub sidebar={ <Sidebar ts={ts} lines = { lines } /> }>
      <div className='container'>
      {/* <p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/WP5sQhGlxj4" title="" frameBorder="0"   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen><br />Powered by <a href="https://youtubeembedcode.com">how to embed a youtube video</a> and <a href="https://unoregler.se/">https://unoregler.se/</a></iframe>
      </p> */}
      {/* <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=1406268342&bvid=BV13r421M7JX&cid=1636658029&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe> */}
        <div className='left t-5 th-3 white' style={{ letterSpacing:'0.05em' }}>
          { lines.map( (_l,_i) =>
            <p className='border-lighten-b py-10' key={_i}>{
              _l.split(' ').map( _w => <span className='mr-5 tapable' onClick={ e => openWordBox( _w, e.target, _l ) }>{ _w }</span> )
            }</p>
          )}
        </div>
      </div>
    </AppFrame>
  )

}

const Sidebar = ({ ts, lines = [] })=>{
  return (
    <div className='fright w-36 h-48 tapable round-full center'
      onClick={ ()=> util.copyText( lines.join('\n') ) && toast( 'Transcript Copied!' )  }>
      <span className='icon icon-copy th-48 primary' />
    </div>
  )
}

const plainLines = ( words = [] )=>{
  return words.map( _l => _l.words.map( _w => _w[2].join(' ') ).join(' ') ).filter( _l => _l.length>1 )
}

export default observer( TranscriptDetail )