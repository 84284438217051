import React from 'react'

import { AppFrame } from '../App'
import Ani from '../../components/ani';
import CardChat from './components/card-chat';
import CardVideos from './components/card-videos';
import PageFooter from '../../components/page-footer';
import ListChats from '../../components/list-chat';

/**
 * @next get chat bots from remote
 */
function Home(){

  return (
    <AppFrame>
      <Ani className='container pb-100'>

        <header className='center my-80'>
          <h1 className='bold' style={{ fontSize:'2.1em', letterSpacing:'-0.02em' }}>
            Crossfit Gym for <span className='border-primary border-b' style={{borderBottomWidth:'4px' }}>Language</span> Learning
          </h1>
        </header>

        <CardChat className='pt-30' />

        {/* Videos */}
        <CardVideos className='mt-30 left bg-ink border-lighten p-20 round'/>

        {/* MyWords */}
        {/* <CardMyWords className='mt-30 left bg-ink border-lighten p-20 round' /> */}
        
        {/* Speaking */}
        {/* <div className='mt-30 left bg-ink p-20 round'>
          <h2 className='bold'>Tracks</h2>
          <p className='t-6 silver'>Repeat sentences, not just word</p>
          <TodayTracks className='mt-20' />
        </div> */}

        {/* Listening: Tracks */}
        {/* <div className='mt-50 left'>
          <TodayTracks />
        </div> */}

        <div className='mt-50 left round '>
          <h2 className='t-5 silver mb-10'>Activity Logs</h2>
          {/* <p className='t-6 silver mb-20'>You recently played.</p> */}
          <ListChats maxSize={5} psize={5} />
        </div>
        
        <div className='mt-100'><PageFooter /></div>
      </Ani>
    </AppFrame>
  )

}

export default Home