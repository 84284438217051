import React from "react";

/**
 * 
 * @param {*} data 
 * @returns 
 */
export default function Row({ title='Row', info='a desc ...', icon='info', children = null, onClick = null }){
  return (
    <div className='box-primary-black flex flex-row flex-center px-20 py-15 round tapable' onClick={ onClick }>
      <div className='flex flex-1 flex-m-1'>
        <div className='left'>
          <p className='t-5 primary bold'>{ title }</p>
          <p className='t-6 silver mt-5' style={{height:'40px'}}>{ info }</p>
        </div>
      </div>
      <div className='flex flex-none pl-15'>
        <span className={`icon icon-${icon} t-3 primary th-64`} />
      </div>
      <div className='flex-none right'>
        { children }
      </div>
    </div>
  )
}