import React, { useState } from "react"
import Input from "../../../components/input"
import { useNavigate } from "react-router-dom"
import util from "../../../core/util"
import useSWRImmutable from "swr/immutable"
import { getLang } from "../../../assets/langs"

export default function CardChat({ className = '', style ={} }){

  const [text,setText] = useState('')
  const navigate = useNavigate()
  const { data: me } = useSWRImmutable('/auth/me')
  const lang = getLang( me?.settings?.language_target || 'en' )
  return  (
    <section className={ className } style={ style }>
      <div>
        <Input focus icons={['icon-writing primary th-54 inline px-10']}
          className="border-lighten border-2"
          placeholder={`Chat in ${lang.nativeName}...`}
          minRows={2}
          value={ text } onChange={setText}
          onEnter={ ()=>{
            util.local.save('chat-user-input', { text })
            navigate(`/chat/create/HolliChat`)
          }} />
      </div>
      <div className="left mt-20 pl-2">
        <p className="inline mr-10 th-36 t-5 silver">Games:</p>
        <div className="inline h-36 round-full tapable bg-ink pl-10 pr-5" onClick={ navigate.bind(null,`/chat/create/Wordle`) } >
          <span className="icon icon-game th-36 silver" />
          <h3 className="ml-5 inline t-6 bold silver th-36">Wordle</h3>
          <span className="ml-2 icon icon-right t-6 mr-5 th-36 silver" />
        </div>
        <div className="inline h-36 round-full tapable bg-ink pl-10 pr-5 ml-5" onClick={ navigate.bind(null,`/chat/create/Scrambles`) } >
          <span className="icon icon-game th-36 silver" />
          <h3 className="ml-5 inline t-6 bold silver th-36">Scrambles</h3>
          <span className="ml-2 icon icon-right t-6 mr-5 th-36 silver" />
        </div>
        {/* <Row icon='game' title='Scrambles' info='Rearrange letters to form real word.' onClick={ navigate.bind(null,`/chat/create/Scrambles`) } /> */}
      </div>
    </section>
  )
}