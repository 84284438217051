import { makeAutoObservable } from "mobx"

const _player = {

  playing : -2, // -2 not ready, -1 at start(paused), 0 paused, 1 playing, 2 ended(paused)
  duration : 0,
  lines : [],
  at : 0,
  speed : 1,
  cc : 1,  // 0 hide  1 LINE  2 ALL

  get lineIdx(){
    const idx = _player.lines.findIndex(  _l => _player.at >= _l.L && _player.at <= _l.end )
    return idx < 0 ? ( _player.lines.length - 1 ) : idx
  },

  getLine( delta = 1 ){
    var toIdx = _player.lineIdx + delta
    toIdx > _player.lines.length - 1 && ( toIdx = 0 )
    toIdx < 0 && ( toIdx = _player.lines.length - 1 )
    return _player.lines[ toIdx ]
  },

  set(key,value){
    this[key] = value
  },
  
  reset(){
    _player.playing = -2
    _player.duration = 0
    _player.lines = []
    _player.at = 0
    return true
  },

}

export default makeAutoObservable( _player )