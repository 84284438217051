import React, { useEffect, useState } from 'react'
import './layer.scss'
import util from '../core/util'
import useSWRImmutable from 'swr/immutable'
import { mutate } from 'swr'

const LAYER_STATE = {
  open: false,
  content: ({ visible }) => <p>No Content</p>,
  mask: '', // 'hide', 'bare'
  delay: 500,
}

/**
 * Open a Layer
 * @param {Function} content
 * @param { string } mask  '' | 'lock' | 'hide' | 'bare'
 * @param { Number } delay for the animation
 * - custom all by pass 'Function'
 */
export const openLayer = async ( content, mask='', delay = 500 )=>{
  if( LAYER_STATE.open ){
    closeLayer(10)
    await util.delay(100)
  }
  await util.delay(2)
  Object.assign( LAYER_STATE, { open: true, content, mask, delay } )
  mutate('layer.open')
}

export const closeLayer = ( delay = 500 )=>{
  LAYER_STATE.delay = delay
  LAYER_STATE.open = false
  mutate('layer.open')
}

/**
 * Component
 */
function Layer(){

  const { data: open = false } = useSWRImmutable('layer.open', () => LAYER_STATE.open )
  const [visible, setVisible] = useState(false)
  const [exist, setExist] = useState(false)
  const { content: Content, mask, delay } = LAYER_STATE
  
  useEffect(()=>{
    if( open === undefined ) return
    const to = !!open
    if( to ){
      setExist(true)
      setTimeout( ()=>setVisible(true), 10 )
    }else{
      setVisible(false)
      setTimeout( ()=>setExist(false), delay )
    }
  },[open,delay])
  
  if( mask === 'bare' ) return <Content visible={ visible } />
  else return exist &&
    <div className='layer'>
      { mask !== 'hide' &&
        <div className={`layer-mask ani-fadein ${visible ? 'visible' : ''}`}
          onClick={ () => mask!=='lock' && closeLayer() }
          onScrollCapture={ e => e.stopPropagation() }/>
      }
      <Content visible={ visible } />
    </div>
}

export default Layer