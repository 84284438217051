import React from 'react'
// import Tab from '../components/tab'
import useSWRInfinite from 'swr/infinite'
import $api from '../core/api'
import useReachBottom from '../hooks/useReachBottom'
import { AppFrame } from './App'
import Row from './Home/components/row'
import { useNavigate } from 'react-router-dom'
import util from '../core/util'

const getKeySwrInfinite = (path, p, lastData) => (lastData && !lastData.length) ? null : `${path}?p=${p}&psize=20`

function Transcript(){

  return (
    <AppFrame>
      <div className='container'>
        <div className='left'>
          <p className='t-2 th-3' >Transcript</p>
        </div>
        <div className='mt-30'>
          <TsList />
        </div>
        <div className='h-160'></div>
      </div>
    </AppFrame>
  )
}

const TsList = ()=>{

  const { size: ps, setSize: setPs, data: tsPage = [] } = useSWRInfinite( 
    getKeySwrInfinite.bind(null,'/transcript/list'),
    $api.get.bind($api),
    { revalidateFirstPage: false }
  )
  const loadListRef = useReachBottom( () => setPs( ps + 1 ) )
  const tss = tsPage.reduce((_ws,_p)=> _ws = _ws.concat(_p) ,[])
  const navigate = useNavigate()

  return tss.length === 0 ?
    // @next Image to show how to use it on youtube
    <p className='center py-20'>No transcribe records.</p>
    :
    <div ref={ loadListRef } className='ts-list left grid grid-cols-1' style={{ gap:'10px' }}>
      { tss.map( _t => 
        <Row icon='cc-sull' key={ _t.id } title={ _t.title } info={ _t.author } onClick={ navigate.bind(null,`/transcript/${_t.id}`) }>
          <span className='t-6 silver'>{ util.time.ago(_t.__assigned_at) }</span>
        </Row>
      )}
    </div>
}


export default Transcript