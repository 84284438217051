import React from "react"

export default function Dots({ size=20, count = 1, animation = false, children = null }){
  return (
    <div className="inline center" style={{
      width: size + 'px',
      height: size + 'px',
      borderRadius: size + 'px',
      paddingTop: size*.2 + 'px',
      background: 'rgba(17, 136, 238, .1)'
    }}>
      <div className="inline bg-primary" style={{
        width: size*.6 + 'px',
        height: size*.6 + 'px',
        borderRadius: size + 'px',
        }}>
          { children }
        </div>
    </div>
  )
}