import React from "react"
import { useState } from "react"
import Input from "./input"
import Button from "./button"

/**
 * 使用OTP登录
 * - 1、从Half获取otp后手动输入进行登录
 * - 2、若在微信内，可一键跳转授权登录
 */
export default function FormOTP({ submit, classes }){

  const [data,setData] = useState({ handle : JSON.parse( localStorage.getItem('gate_last_handle') ), otp : '' })
  const onInput = (k,v)=>{
    setData( Object.assign( {}, data, { [k] : v } ) )
  }
  const __submit = ()=>{
    localStorage.setItem('gate_last_handle', JSON.stringify(data.handle) )
    return submit( data.handle, data.otp )
  }
  
  return (
    <div className={`OTPForm ${classes}`}>
      <Input className='border-lighten bg-ink mb-10'
        placeholder='Account name' icons={ ['icon-account gray px-10']}
        value={ data.handle } onChange = { v => onInput('handle',v) }/>
      <Input className='border-lighten bg-ink mb-10'
        placeholder='One-time password' icons={ ['icon-psw gray px-10']}
        value={ data.otp } onChange = { v => onInput('otp',v) }/>
      <Button classes="block"
        text="Sign in" disabled={ data.handle.length < 5 || data.otp.length < 6 }
        onClick={ __submit }/>

      {/* @depreceted
      { isWX &&
        <div className='center mt-20'>
          <p className="t-5 mb-20">or</p>
          <Button classes='block' text='微信一键连接' color={['yellow','white']}
            onClick={ () => window.location = `${env.endpoint.half_otp}?to=${ window.location.href.split('?')[0] }` }/>
        </div>
      } */}

    </div>
  )
}