import React, { useEffect } from 'react';
import { Navigate, Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import Layer, { closeLayer } from '../components/layer';
import { Tooltip } from 'react-tooltip';
import useSWRImmutable from 'swr/immutable';
import mixpanel from 'mixpanel-browser';
import Loading from '../components/loading';
import $v from '../core/values';
import BoxWord from '../components/box-word';
import Topnav from '../components/topnav';

function App({ publicPaths = [] }){

  const location = useLocation()
  
  const { data: authed, isLoading: authing } = useSWRImmutable('/auth/me')
  const requireAuth = !publicPaths.find( _p => location.pathname.indexOf(_p) > -1 )

  useEffect( ()=>{ closeLayer() },[ location ] )
  // mixpanel statistic
  useEffect( ()=>{ authed && mixpanel.identify( authed.id ) }, [authed] )
  useEffect(() => { mixpanel.track('Page View', { path: location.pathname }) }, [location])

  // @next move this to loader
  if( authing ) return <div className='fix-t w-100 center' style={{ top:'50%', marginTop:'-32px' }}><Loading color='#e7ff00'/></div>
  else if( !authed && requireAuth ) return <Navigate to='/gate' replace />
  else if( authed && Object.keys( authed.settings ).length < 1 && location.pathname!=='/onboard' ) return <Navigate to='/onboard' replace />

  return (
    <div id='app' className='app'>
      <Outlet />
      <BoxWord />
      <Layer />
      <Tooltip id='toptip' className='round' style={{ zIndex:1000 }}/>
      <ScrollRestoration />
    </div>
  )
}

const AppFrame = ({ children, title='', sub=false, sidebar = null })=>{
  //@fix Mobile Frame
  return !$v.ism ? (
    <div className='app'>
      {/* Navbar */}
      <div className='w-100 h-84'>
        <div className='fix-t w-100 h-84  bg-black-blur' style={{ zIndex:99 }}>
          <Topnav title={title} sub={sub} />
        </div>
      </div>

      {/* Main Content */}
      <div className='w-100 center' style={{ width:'100vw', padding:'0 280px', textAlign:'center' }}>
        { children }
      </div>

      {/* Side Bar (right) */}
      { sidebar &&
        <div className='fix-t pt-30 pb-50 px-20' style={{
          width:'280px', height:'100vh', left:'auto', right:0, zIndex:100, overflow:'scroll',
          borderRight: '1px rgba(255,255,255,.07) solid'
        }}>
          { sidebar }
        </div>
      }
    </div>
  ) : <p>Mobile Frame Support Work</p>
}

/**
 * @warning 只有通过loader页面已加载才会添加，所以在loader中是无法获取到 当前要加载的path
 * @fix replace to export const RouteStack
 */
// Layout.Stack = []
// const action = useNavigationType()
//   useEffect( ()=>{
//     if( action === 'REPLACE' ){ Layout.Stack.pop() }
//     if( action === 'POP' ){
//       Layout.Stack.pop()
//       if( Layout.Stack.length !== 0 ) return
//     }
//     Layout.Stack.push( Object.assign({ from_action : action },location) )
//     closeLayer()
//   },[location, action ] )

export default App
export { AppFrame }
