import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';

import $router from './core/router';
import mixpanel from "mixpanel-browser"
import './index.scss';
import { SWRConfig } from 'swr';
import $api from './core/api';
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton';

mixpanel.init('159a73a256d16f9fe82403f5bee17638', { debug: true, track_pageview: true, persistence: 'localStorage' })

ReactDOM.createRoot(document.getElementById('root')).render(
  <SWRConfig value={{
    fetcher: $api.get.bind($api),
    shouldRetryOnError: false,
    onError: e => console.log(e),
  }}>
    <SkeletonTheme baseColor="#222" highlightColor="#272727">
      <RouterProvider router={ $router } />
    </SkeletonTheme>
  </SWRConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
