import React, { useEffect } from "react"
import { useState } from "react"
import Input from "./input"
import Button from "./button"
import $api from "../core/api"
import toast from "../core/toast"
import CFTurnstile from '../components/CFTurnstile'

/**
 * 使用OTP登录
 * - 1、从Half获取后手动输入进行登录
 * - 2、若在微信内，可一键跳转授权登录
 */
export default function FormEmail({ className = '', onSubmit }){

  const [data,setData] = useState({ email : localStorage.getItem('gate_last_email') || "", password : '', repassword : '' })
  const [ emailState, setEmailState ] = useState(-1)
  const [ cfToken, setCfToken ] = useState(false)

  const onInput = (k,v)=> setData( Object.assign( {}, data, { [k] : v } ) )

  const toCheckEmail = _email => _email.length > 5 && $api.post('/auth/check-email', { email: _email }).then( existed => setEmailState(existed ? 1 : 0) )

  const __submit = ()=>{
    localStorage.setItem('gate_last_email', data.email )
    if( emailState !== 1 && data.password !== data.repassword ) return toast('Password not match','error')
    else onSubmit && onSubmit({ email:data.email, password:data.password, cfToken }, emailState === 1)
  }

  useEffect(()=>{ setEmailState(-1) },[data.email])

  return (
    <div className={`com-form-email ${className}`}>
      <div>
        <Input className='border-gray bg-ink mb-10' focus
          placeholder='Email' icons={ ['icon-email gray px-10']}
          value={ data.email }  onChange={ v => onInput('email', v) } />
        { !cfToken && <CFTurnstile onVerify={ _t => { console.log(_t); setCfToken(_t) } } /> }
        { emailState === -1 &&
          <Button classes="block"
            text="Continue with email" disabled={ data.email.length < 5 || !cfToken }
            onClick={ ()=> toCheckEmail(data.email) } />
        }
      </div>

      <div>
        { emailState > -1 &&
            <Input className='border-gray bg-ink mb-10'
              placeholder='Password' icons={ ['icon-psw gray px-10']}
              value={ data.password } onChange = { v => onInput('password',v) } />
        }

        { emailState === 0 && // repassword when email checked
          <Input className='border-gray bg-ink mb-10'
              placeholder='Repeat Password' icons={ ['icon-psw gray px-10']}
              value={ data.repassword } onChange = { v => onInput('repassword',v) } />
        }
      </div>

      {  emailState > -1 && cfToken && // button when email checked
        <Button classes="block"
          text={["Create Account",'Login'][emailState]} disabled={ data.email.length < 5 || data.password.length < 6 }
          onClick={ __submit } />
      }
    </div>
  )
}