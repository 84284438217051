import util from "../../core/util"

/**
 * parse srt to lines
 * @param {string} srt 
 * @param {number} offset seconds
 * @returns [{ idx, text, start, end },..]
 */
export const srt2lines = ( srt, offset = 0 ) => {
  if( !srt ) return []
  return srt.split('\n\n').map( _l => {
    if( !_l ) return null
    const [idx,time,text] = _l.split('\n');
    const [start,end] = time.split(' --> ')
    return { idx : Number(idx), text, start: util.time.time2seconds(start) + offset, end: util.time.time2seconds(end) + offset }
  }).filter( _l => _l )
}

/**
 * 
 * @param {Array} lines 
 * @param {Number} maxGap not stick lines when gap > maxGap 
 * @param {Number} maxDuration not stick lines when pre line.duration > maxDuration
 * @returns 
 */
export const stickLines =  ( lines = [], maxGap = .2, maxDuration = 2 ) =>{
  const rows = []  // [ [line,line], [line,line] ]
  const extendRow = ( _l, _sticky = false ) => {
    if( rows.length < 1 || !_sticky ) return rows.push( _l )
    const last = rows[rows.length - 1]
    rows[rows.length - 1] = {
      idx: last.idx + '-' + _l.idx,
      text: last.text + ' ' + _l.text,
      start: last.start,
      end: _l.end,
    }
  }
  lines.forEach( (_l,_i)=>{
    if( _i === 0 ) return extendRow( _l, true )
    const prevLine = rows.slice().pop()
    var _sticky = true
    if( _l.start - prevLine.end > maxGap ) _sticky = false
    if( prevLine.end - prevLine.start > maxDuration ) _sticky = false
    // const _sticky = 
    //   ||( prevLine.text.match(/[.?!:？。！：]\s*$/) ||  )
    extendRow( _l, _sticky )
  })
  // add next.L = prev.end and prev.R = next.start
  return rows.map( (_r,_i) => {
     _r.L =  rows[_i-1] ? rows[_i-1].end : 0
    rows[_i+1] && ( _r.R = rows[_i+1].start )
    return _r
  })
}