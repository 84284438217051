import React from 'react'
import { useNavigate } from 'react-router-dom'
import BoxSettings from '../components/box-settings'
import util from '../core/util'

function Onboard(){

  const navigate = useNavigate()

  return (
    <div className='container py-50'>
      <div>
        <h2 className='inline t-2 border-b border-primary' style={{ borderWidth:'3px' }}>Launch</h2>
      </div>
      <BoxSettings onSaved={ ()=> { util.confetti(); navigate('/',{ replace: true }) } } />
    </div>
  )

}

export default Onboard