import React from "react";

/**
 * @param0 {array} bars [ bgBar, bar1, bar2 ]
 * @returns 
 */
function ProgressBar({
  width = '100px',
  height = '8px',
  bars = [ [10,'ink'], [3,'primary'], [2,'yellow'] ],
}){
  const [ w, wUnit ] = width.match(/(\d+)(.+)/).slice(1)
  const [ h, hUnit ] = height.match(/(\d+)(.+)/).slice(1)
  return (
    <div className={`inline round bg-${bars[0][1]}`}
      style={{ width, height, padding:'1px' }}>
      { bars.map( ([_n,_c], _i) =>
        <div key={ _i } className={`ab-tl inline round bg-${_c}`} style={{
          top: '1px', left: '1px',
          width: Number(_n)/ Number(bars[0][0]) * Number(w) +wUnit,
          height: ( Number(h) - 2 ) + hUnit
        }}></div>
      )}
    </div>
  )
}

export default ProgressBar