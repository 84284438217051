import React from "react"
import _player from "../_player"
import Loading from "../../../components/loading"
import $v from "../../../core/values"

export default function TrackHeader({ track = { state : 0, r_Track : {} } }){

  const { playing } = _player

  if( !track.r_Track.title ) return null
  return (
    <div className="py-50">
      <p className="bold mb-10">#{track.r_Track.id}</p>
      <h2 className='center t-1 mb-50' style={{ fontWeight:'lighter' }}>{ track.r_Track.title }</h2>
      { playing === -2 && <Loading duration='1.2s' /> }
      { !$v.ism && playing === -1 && <p className="t-5 silver ani-blink th-32">Press <span className='hotkey mx-5'>Space</span> to play</p> }
    </div>
  )
}