import React, { useEffect, useState } from "react"

/**
 * Will add class 'visible' delayed at show
 */
export default function Ani({ className='', style={}, type='fadeup', show=true, persist=false, delay = 10, children, ...props }){

  const [visible,setVisible] = useState(false)

  useEffect( ()=>{
    const timer = setTimeout( () => setVisible( show ), delay )
    return () => clearTimeout( timer )
  }, [show, delay] )

  return ( persist || show ) &&
    <div className={ className + ' ' + ( visible ? 'visible' : '' ) + ` ani-${type}` } style={ style } {...props } >
      { children }
    </div>
}