import React, { useMemo } from "react"
import Button from "../../../components/button"
import { useNavigate } from "react-router-dom"
import Dots from "../../../components/dots"
import useSWRImmutable from "swr"

export default function TrackEnd({ track={ tid : 0 }, onReplay, onNext }){

  const navigate = useNavigate()
  const { data: tracks = [] } = useSWRImmutable('/track/today')

  const remains = useMemo(()=> tracks.filter( _t => _t.state === 0 ).length, [tracks] )
  const nextTrack = useMemo(()=> tracks.find( _t => _t.state === 0 )  , [tracks] )

  return(
    <div className='center py-50'>
      <div><Dots size={88}><span className='white icon icon-check t-2' style={{ lineHeight: 88*.6+'px' }} /></Dots></div>
      <p className='mt-10 t-2 bold yellow'>{ remains > 0 ? 'Cheers!' : 'All down'  }</p>
      <p className='t-5'>You just completed one more track ( { tracks.length - remains }/{ tracks.length } for today ).</p>
      <div className='center mt-50'>
        <div className="mb-10">
          <Button text='Replay'  icons={['refresh']} style={{ width:'160px' }}
            onClick={ onReplay } />
        </div>
        { remains > 0 ?
          <div className="mb-10">
            <Button text='Go Next' ghost color={['silver','white']} icons={['play']} style={{ width:'160px' }}
              onClick={ () => onNext(nextTrack.id) }>
              <span className="ab-tr t-7 th-24 bold black bg-yellow round-full wh-24 inline">{ remains }</span>
            </Button> 
          </div>
          :
          <div className="mb-10">
            <Button text='Thanks' ghost color={['silver','white']} style={{ width:'160px' }}
              onClick={ () => navigate(-1) } />
          </div>
        }
      </div>
    </div>
  )
}