import { useEffect, useState } from "react";


export default function useExtDom() {

  const [domHo,setDomHo ] = useState(null)
  useEffect(() => {
    const _t = setTimeout(()=>{
      try{
        var _ho
        document.querySelectorAll('plasmo-csui').forEach( _d => _ho = _d.shadowRoot.querySelector('#ho') )
        setDomHo( _ho ? _ho : false )
      }catch(e){}
    },1000)
    return () => { clearTimeout(_t) }
  }, [domHo])
  return domHo
};