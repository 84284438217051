import { useEffect, useState } from "react"
import Events from "../assets/Events"

const $events = new Events()

/**
 * 
 * @param {*} key 
 * @param {*} preset 
 * @returns {[ any, Function ]}
 */
function useEventState( key, preset = {} )
{
  const [params, setParams] = useState(preset)
  useEffect(()=>{
    return $events.bind(key, setParams, setParams )
  },[key,setParams])
  return [params, _p => $events.fire(key, _p) ]
}

function useEvent(key, cb){
  useEffect(()=>{
    const _l = $events.bind(key, cb)
    return $events.unbind(key, _l)
  }, [ key, cb] )
}

const fireEvent = (key, resolver) => {
  return $events.fire( key,
    typeof resolver === 'function' ? resolver($events.lastFires[key])
      : resolver
  )
}
export { useEvent, useEventState, fireEvent }