import React from 'react'
import { AppFrame } from './App'
import Ani from '../components/ani'
import ListWords from '../components/list-words'

function Vocab(){

  return (
    <AppFrame>
      <Ani className='container'>
        <div className='mt-30'>
          <ListWords />
        </div>
        <div className='h-160'></div>
      </Ani>
    </AppFrame>
  )
}

export default Vocab