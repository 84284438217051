import React from "react"
import useSWRImmutable from 'swr/immutable'
import $api from "../core/api"
import Loading from "./loading"
import Box from "./box"
import { action, observable } from "mobx"
import util from "../core/util"
import { observer } from "mobx-react-lite"
import { fireEvent } from "../hooks/useEvent"

/**
 * Translate text
 * @param {*} param0 
 * @returns 
 * @next many words could not be found, maybe open it to bing/google
 */
const BoxWordState = observable({
  anchorRect: null,
  text:null,
  context: null,
})

const openWordBox = action(( text, anchorTarget, context='' ) => {
  if( text && anchorTarget ){
    BoxWordState.text = text
    BoxWordState.anchorRect = util.getElementPosition(anchorTarget)
    BoxWordState.context = context
  }
})

const closeWordBox = action(() => {
  BoxWordState.text = null
  BoxWordState.anchorRect = null
  BoxWordState.context = null
})

window.addEventListener('scroll', closeWordBox)

function BoxWord(){

  const { text, context, anchorRect } = BoxWordState

  const { data: me={ settings : { language_native: 'zh-Hans' } } } = useSWRImmutable('/auth/me')
  const { data: word = { translation: { defs : {} }}, isLoading : translating } = useSWRImmutable( ['/translate/word', me, text, context ], async ()=> {
    const to = me?.settings?.language_native || 'zh-hans'
    if( !text ) return { defs: {} }
    const _d = await $api.post('/translate/word', { word:text, to, context }).then( _w=> {
      _w && fireEvent('/word/list', _raw => util.arrObj.presert(_raw, _w) )
      return _w
    })
    !_d && closeWordBox()
    return _d || undefined
  })

  return (
    <Box className="com-box-word p-10" anchorRect={ text && anchorRect } ani="popin" width={320}>
      <div className="p-15 round border-lighten bg-ink tapable" onClick={ closeWordBox }>

        {/* 收藏 */}
        <div className="flex-row">
          <p className="flex-1 flex-m-1 t-3 left yellow th-32">{ text }</p>
          <div className="flex-none">{/* right */}</div>
        </div>

        {/* 释义 */}
        <div className="mt-10" >{
          translating ? <div className="center py-30"><Loading size={50} /></div>
          : word.translation && Object.keys( word.translation?.defs ).map( _pos =>
            <div className="border-lighten-b mb-20 left" key={ _pos }>
              <p className="th-6"><span className="t-6 px-5 bg-ink round mr-10 border-lighten">{ _pos.toLowerCase() }.</span></p>
              { word.translation?.defs[_pos].map( (_t,_i) =>
                <span key={_i} className="t-5 mr-10">{ _t }</span>
              )}
            </div>
          ) 
        }</div>
      </div>
    </Box>
  )
}

export default observer( BoxWord )
export { openWordBox, closeWordBox }