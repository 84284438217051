import useSWRInfinite from "swr/infinite";
import $api from "../core/api";
import { useEventState } from "./useEvent";
import { useState } from "react";
import util from "../core/util";

export default function useList( path, psize=20, queryString = 'app=1' ) {

  const [items, setItems] = useEventState(path,[])
  const [overed, setOvered] = useState(false)

  const { size: ps, setSize: setPs, isLoading } = useSWRInfinite( 
    (p, lastData) => (lastData && !lastData.length) ? null : `${path}?p=${p}&psize=${psize}&${queryString}`,
    _k => $api.get(_k).then( _d => {
      if( _d && _d.length > 0 ) setItems( util.arrObj.upsert( items, _d ) )
      else setOvered(true)
      return _d
    }),
    { revalidateFirstPage: false, }
  )

  return { items, setItems, next: ()=>setPs(ps+1), overed, isLoading }
};