import React from "react"

export default function Loading({ size = 100, color="#e7ff00", duration = '2s' }){

  const style1 = {
    width: size + 'px',
    height: size/40 + 'px',
  }
  const style2 = {
    width: size + 'px',
    height: size/40 + 'px',
    minWidth:'5px',
    background: color,
    borderRadius:  size/80 + 'px',
    animation: `loading-dot-line ${duration} infinite`,
  }

  return (
    <div className="inline" style={ style1 }>
      <div style={ style2 }></div>
    </div>
  )

}