import axios from "axios"
import autoMutate from "./autoMutate"
import toast from "./toast"
import { mutate } from "swr"

/**
 * Felt: the minimalism Request and Data Layer
 * - Handle JWT Token in request.headers
 */
const TOKEN_KEY = 'api_token'
const endpoint = {
  // development: 'http://127.0.0.1:3721',
  development: 'https://api.holli.cc',
  production: 'https://api.holli.cc',
}[process.env.NODE_ENV]

const local_token = JSON.parse( localStorage.getItem( TOKEN_KEY ) )
class Api{

  endpoint
  headers = {
    Accept : 'application/json',
    Authorization : local_token ? `Bearer ${ local_token }` : null,
  }
  onError = console.log

  constructor( endpoint, onError ){
    this.endpoint = endpoint
    this.onError = onError
  }

  /**
   * @return {Promise<*>} data or throw
   * @param {boolean} canThrow if true, will resolve(false) when error.
   */
  async get( path, canThrow = false, options= {} ){
    return this.__request( path, null, { ...options, method: 'GET' }, canThrow )
  }

  /**
   * @return {Promise<*>} data or throw
   * - Will auto mutate key cache defined in mutator.js
   */
  async post( path, data={ }, canThrow = false, options = {} ){
    const resData = await this.__request( path, null, { ...options, method: 'POST', data }, canThrow )
    resData && autoMutate( path, resData )
    return resData
  }

  /**
   * Get Readable Stream
   */
  async getStream( path, handle ){
    const res = await fetch( this.endpoint+path, {  headers: this.headers, })
    if (!res.ok) { throw Object.assign({ code: res.status }, await res.json()) }
    const decoder = new TextDecoder("utf-8")
    const reader = res.body.getReader();
    var content = ''
    while(true){
      const { done, value } = await reader.read();
      const _t = decoder.decode( value ); content += _t
      handle({ done, value : done ? content : _t })
      if( done ) break
    }
  }

  async clear(){
    return $api.get('/auth/logout').catch(e=>e).then( ()=> {
      this.__setToken(null)
      mutate('/auth/me',null)
      return setExtToken('logout')
    })
  }

  /**
   * Handle Token and Error
   * @return {Promise<*>} data
   */
  async __request( path, data = {}, options = {}, canThrow = false ){
    // console.log('[call api]', `${options.method}::${path}`)
    const req = Object.assign({
      url: this.endpoint+path,
      data,
      headers: this.headers
    }, options )
    return await axios( req ).then(res => {
      res.headers.token && this.__setToken( res.headers.token )
      return res.data
    }).catch( e => {
      if( canThrow ) throw e
      this.onError(e)
      return undefined
    })
  }

  __setToken(token){
    localStorage.setItem( 'api_token', JSON.stringify(token)  )
    this.headers.Authorization = `Bearer ${ token }`
    return true
  }

}

const $api = new Api( endpoint, _e => {
  const error = { code: 400, message: 'Request Error' }
  if( axios.isAxiosError(_e) ){
    if( typeof _e === 'string' ){
      error.message = _e
    }else{
      error.code = Number(_e.code)
      error.message = _e.response?.data.message || _e.response?.data || _e.response?.statusText || _e.code || 'Request failed!'
    }
  }
  toast( error.message, 'error' )
})

const setExtToken = async token => {
  token = token || JSON.parse( localStorage.getItem( TOKEN_KEY ) )
  await fetch(`${endpoint}/ext/token?t=${token}`).catch(e => e)
}

export default $api
export {
  endpoint,
  setExtToken,
} 