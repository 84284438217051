import React from "react";
import Turnstile, { useTurnstile } from "react-turnstile";

// For testing
// 1x00000000000000000000AA	Always passes	visible
// 2x00000000000000000000AB	Always blocks	visible
// 1x00000000000000000000BB	Always passes	invisible
// 2x00000000000000000000BB	Always blocks	invisible
// 3x00000000000000000000FF	Forces an interactive challenge	visible

const sitekey = {
  development: '1x00000000000000000000AA',
  production: '0x4AAAAAAAi8CUnyOYY70Mrx',
}[process.env.NODE_ENV]

// ...
export default function CFTurnstile({ onVerify }) {
  useTurnstile()
  return (
    <Turnstile theme="dark" sitekey={ sitekey } onVerify={ onVerify } />
  );
}