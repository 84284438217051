import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Callback when element's bottom showed in window
 * @param {*} callback 
 * @return targetRef
 */
export default function useReachBottom( callback, over = 0 ){

  const [reached, setReached] = useState(false)
  const targetRef = useRef(null)

  const handleScroll = useCallback(()=>{
    if (!targetRef.current) return
    const rect = targetRef.current.getBoundingClientRect()
    if( Math.floor( rect.bottom + over ) <= window.innerHeight ){
      if( !reached ){
        setReached(true)
        callback()
      }
    }
    else setReached(false)
  },[targetRef,callback,reached, setReached, over])

  useEffect(() => {    
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => document.removeEventListener('scroll', handleScroll )
  }, [handleScroll])

  return targetRef
}