import React from "react";
import { MsgUserInput, MsgUserNormal } from "./msg-user"
import { MsgBotNormal, MstBotCompletionStream } from "./msg-bot"

const Types = {
  // from.type.pendingType
  'user.TEXT.': MsgUserNormal ,
  'user.TEXT.input': MsgUserInput,
  'bot.TEXT.': MsgBotNormal,
  'bot.TEXT.completion_stream': MstBotCompletionStream,
  'system.TEXT.': ({ data, onChange }) => null,
}

export default function Msg({ data }){
  const typeKey = [ data.from, data.type, data.pending ? data.pending.type : '' ].join('.')
  const Com =  Types[ typeKey ]
  return Com ? <Com data={data} /> : <p className="red py-20 center">Unknown Message Type<br/>{JSON.stringify( data )}</p>
}

// document.addEventListener('selectionchange', function(e){
//   const s = document.getSelection()
//   console.log('[selectionchange]',e, s.toString())
// })
