/**
 * 事件管理
 * const ev = new Events( ['app.init','event.change'] )
 * event.fire( 'event.change' , args )
 * event.bind( 'event.change', fn )
 */
export default class Events{
  
  // usage: event.fire('event.init', {  })
  binds = {
    // 'app.init' : {
    //   id : params => console.log('event.inited',params),
    //   // ...
    // }
  }

  lastFires = {
    // event key: params
  }

  constructor( keys = [] ){
    keys.forEach( key => {
      this.binds[key] = {}
    })
  }

  fire( key, params={} ){
    // console.log( 'event::'+key, params )
    try{
      if( !this.binds[key] ) return false // console.log(`${key} nobinds`)
      this.lastFires[key] = params
      Object.values(this.binds[key]).forEach( fn => {
        try{ fn( params, this ) }
        catch(e){ console.log( key, fn, e ) }
      })
    }catch(e){ console.log(e) }
  }

  bind( key, fn, init = null ){
    // console.log( 'bind::'+key )
    if( !this.binds[key] ) this.binds[key] = {}
    const id = Math.random().toString(36).slice(2)
    this.binds[key][id] = fn
    init && this.lastFires[key] && init( this.lastFires[key] )
    return this.unbind.bind( this, key, id )
  }

  unbind( key, id ){
    try{
      delete this.binds[key][id]
      // console.log( 'Event Deleted:', key, id )
    }catch(e){ console.log(e) }
  }

}

// setTimeout( ()=> console.log('events', Events ), 1000 )
// event.on( 'event.fired', TEFDCD.refresh() )