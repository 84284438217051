import React from "react"
import { useState } from "react"
import Loading from "./loading"
import toast from "../core/toast"

export default function Button({
  text='BUTTON',
  icons = [],
  children = null,
  onClick = null,

  color=['primary','black'],  //[ bgColor, foreColor ]
  disabled = false,
  size=48,
  ghost=false,
  classes = '', // for override
  style = {},
}){

  const [pending,setPending] = useState(false)
  const toClick = ()=>{
    if( disabled ) return toast('Check the inputs','error')
    else if( pending ) return toast('Processing...')
    setPending(true)
    Promise.resolve( !disabled && onClick && onClick() ).then( ()=> setPending(false) )
  }

  const lineHeight = ( size + ( ghost ? -1 : 0 ) ) + 'px'
  return (
    <div className={`${classes} inline h-${size} px-15 ${ ghost ? 'border' : 'bg' }-${color[0]} tapable center round-full`}
      style={ Object.assign({ opacity: (disabled || pending ? 0.4 : 1 ) }, style )}
      onClick={ toClick }>
      { pending && <div className="inline py-5"><Loading size={ (size-10)/100 } color="#1b1b1b" /></div> }

      { !pending && icons[0] &&
        <span className={`icon icon-${icons[0]} ${color[1]}`} style={{ marginRight : size/10+'px', lineHeight, left: size/2 }}/> }
      { !pending &&
      <span className={`${color[1]} bold`} style={{ fontSize : 0.3*size+'px', lineHeight }}>{text}</span> }
      { !pending && icons[1] &&
        <span className={`icon icon-${icons[1]} ${color[1]}`} style={{ marginLeft : size/10+'px', lineHeight }}/> }
      { children }
    </div>
  )
}